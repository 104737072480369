/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ResetPassword from 'components/Auth/ResetPassword';
import AppContainer from 'containers/AppContainer';
import Auth0Redirect from 'components/Auth/Auth0Redirect';
import SSOLogin from "components/Auth/SSOLogin";
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ExtractHistoryHack} from 'businessLogic/historyHack';
import {loadFromStorage} from 'businessLogic/localStorage';
import Logout from 'components/Auth/Logout';
import ForgotPassword from 'components/Auth/ForgotPassword';
import {datadogRum} from '@datadog/browser-rum';
import {getBugsnag} from '../../src/app/services';
import Auth0Unauthorized from './Unauthorized/Auth0Unauthorized';
import {CookieGroups} from "typedefs/onetrust";
import {once} from "lodash";
import SplitProvider from './SplitIo';

const bugsnag = getBugsnag();
const { userId, token } = loadFromStorage();

export interface Props {
  configData: any;
  userEmail: string;
  setConfigFromPage: (config: any) => void;
  setUserToken: (id: number | void, token: string | void) => void;
  ejectUser: () => void;
  fetchFeatures: () => Promise<void>;
}

interface State {
  splitConfig?: SplitIO.IBrowserSettings;  // Replace 'any' with the actual type of your splitConfig
}

class Root extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      splitConfig: undefined,
    };

    this.configureBugsnag();
    this.configureOnetrust();
    this.configureSplit();
    props.setConfigFromPage(props.configData);
    props.fetchFeatures();

    if (userId && token) {
      props.setUserToken(userId, token);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.userEmail !== this.props.userEmail) {
      this.configureSplit();
    }
  }

  configureBugsnag = () => {
    const babelEnv = (typeof process !== 'undefined' && process.env && process.env.BABEL_ENV) || 'undefined';
    if (babelEnv !== 'mocha') {
      bugsnag.setOptions({
        apiKey: this.props.configData.bugsnag.key,
        appVersion: this.props.configData.releaseVersion,
        releaseStage: this.props.configData.releaseStage,
      });
    }
  }

  configureOnetrust = () => {
    window.OptanonWrapper = () => {
      const activeGroups = (window?.OptanonActiveGroups?.split(',') || []) as CookieGroups[];
      if (activeGroups.indexOf("C0002") > -1) {
        //prevent datadog from being re-initialized if opting back in
        this.configureRum();
      }
    };
  }

  configureRum = once(() => {
    const envName = window.CONFIG?.config.releaseStage || this.props.configData.releaseStage;
    if (envName === 'prod' || envName === 'demo') {
      datadogRum.init({
        applicationId: '9bbfc974-1e75-4efb-b63b-928668dceba1',
        clientToken: 'pube3daea2776b2c219537fa722d00d5874',
        site: 'us5.datadoghq.com',
        service: 'conversations-admin-ui',
        env: this.props.configData.releaseStage,
        version: this.props.configData.releaseVersion,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  });

  configureSplit = () => {
    const authorizationKey = window.CONFIG?.config?.split?.authorizationKey
    ? window.CONFIG.config.split.authorizationKey
    : this.props.configData.split?.authorizationKey;

    if (!authorizationKey) return;

    const key = this.props.userEmail || 'key';

    this.setState({
      splitConfig: {
        core: {
          authorizationKey,
          key,
        },
      }
    });
  }

  render() {

    return (
      <React.Fragment>
        <SplitProvider config={this.state.splitConfig}>
          <ExtractHistoryHack />
          <Switch>
            <Route exact={true} path="/auth0/login" render={() => (
              <Auth0Redirect {...this.props} />
            )} />
            <Route exact={true} path="/auth0/login/success" component={SSOLogin} />
            <Route
              exact={true}
              path="/logout"
              render={(): JSX.Element => (
                <Logout
                  ejectUser={this.props.ejectUser}
                  logoutUrl={this.props.configData.auth0.logoutUrl}
                />
              )}
            />
            <Route exact={true} path="/auth0/unauthorized" component={Auth0Unauthorized} />
            <Route exact={true} path="/password/email" component={ForgotPassword} />
            <Route path="/password/reset/:hash" component={ResetPassword} />
            <Route exact={true} path="/" component={AppContainer} />
            <Route path="/chat" component={AppContainer} />
            <Route path="/admin" component={AppContainer} />
            <Route path="/switch" component={AppContainer} />

            {/* legacy routes */}
            <Redirect from="/billingreport" to="/admin/report/call-center-billing" />
          </Switch>
        </SplitProvider>
      </React.Fragment>
    );
  }
}

export default Root;
