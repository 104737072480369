import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactGA from 'react-ga';
import Loadable from 'react-loadable';
import Modal from 'react-modal';
import { Redirect, Route, Switch, RouteChildrenProps } from 'react-router-dom';
import ConversationsLoader from 'components/Shared/ConversationsLoader/ConversationsLoader';
import NoMatch from 'components/NoMatch/NoMatch';
import InvalidToken from 'components/Shared/InvalidToken/InvalidToken';
import UnsupportedBrowser from 'components/Shared/UnsupportedBrowser/UnsupportedBrowser';
import FullPageLoading from 'components/Shared/Loadable/FullPageLoading';
import { getBugsnag } from '../../src/app/services';
import { getAppEnv } from 'businessLogic/util';

let hasStartedRecording = false;

const bugsnag = getBugsnag();

const AdminAppContainer = Loadable({
  loader: () => import(/* webpackChunkName: 'admin' */ 'containers/AdminAppContainer/AdminAppContainer'),
  loading: FullPageLoading,
  delay: 3000,
});

const ChatAppContainer = Loadable({
  loader: () => import(/* webpackChunkName: 'chat' */ 'containers/ChatAppContainer/ChatAppContainer'),
  loading: FullPageLoading,
  delay: 3000,
});

const UserSwitcherContainer = Loadable({
  loader: () => import(/* webpackChunkName: 'switcher' */ 'containers/UserSwitcherContainer/UserSwitcherContainer'),
  loading: FullPageLoading,
  delay: 3000,
});

interface Props {
  userId: number;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  tokenIsValid: boolean;
  isBrowserSupported: boolean;
  isSuperAdmin: boolean;
  getBrowserConfig: () => void;
  validateToken: () => Promise<void>;
  fetchProfile: (userId: number) => Promise<void>;
  fetchUserOverview: () => Promise<void>;
  fetchUserAccountsByAuth0Id: () => Promise<void>;
  fetchUserCustomers: () => Promise<void>;
  connectToPusher: () => Promise<void>;
  joinControlChannel: () => void;
  onWindowFocusChange: (focused: boolean) => void;
  onConnectionChange: (isOnline: boolean) => void;
}

interface State {
  loaded: boolean;
}

export default class App extends React.Component<Props & RouteChildrenProps, State> {
  state = { loaded: false };
  tokenValidationPoller: number;
  windowFocused: () => void;
  windowBlurred: () => void;
  connectionOnline: () => void;
  connectionOffline: () => void;

  constructor(props: Props & RouteChildrenProps) {
    super(props);
    App.configureModalDefaults();
    this.windowFocused = this.props.onWindowFocusChange.bind(null, true);
    this.windowBlurred = this.props.onWindowFocusChange.bind(null, false);
    this.connectionOnline = this.props.onConnectionChange.bind(null, true);
    this.connectionOffline = this.props.onConnectionChange.bind(null, false);
  }

  async componentDidMount() {
    // validate token
    try {
      await this.props.validateToken();
      this.tokenValidationPoller = setInterval(this.checkToken.bind(this), 30000) as unknown as number;
    } catch (e) {
      this.props.history.push(`/auth0/login${document.location.hash}`);
      return;
    }

    // preload the chat and admin bundles so when/if they are needed, they will already be loaded
    ChatAppContainer.preload();
    AdminAppContainer.preload();
    UserSwitcherContainer.preload();

    // add event listeners for window focus
    window.addEventListener('focus', this.windowFocused);
    window.addEventListener('blur', this.windowBlurred);

    // add event listeners for connection status
    this.props.onConnectionChange(navigator.onLine); // set initial online status
    window.addEventListener('online', this.connectionOnline);
    window.addEventListener('offline', this.connectionOffline);

    // configure stuff
    this.props.getBrowserConfig();
    this.configureBugsnag();
    this.configureGoogleAnalytics();

    // fetch user info
    await this.props.fetchUserOverview();
    await this.props.fetchProfile(this.props.userId);
    await this.props.fetchUserCustomers();
    await this.props.fetchUserAccountsByAuth0Id();
    await this.props.connectToPusher();
    this.props.joinControlChannel();

    this.setState({ loaded: true });

    const envName = getAppEnv();

    if (!hasStartedRecording && this.props.userId && (envName === 'prod' || envName === 'demo')) {
      const internalEmails = ['dealerinspire.com', 'cars.com', 'creditiq.com', 'dealerrater.com'];
      const internalEmailRegex = new RegExp(`@(${internalEmails.join('|')})$`);
      const isInternalUser = internalEmailRegex.test(this.props.userEmail);

      datadogRum.setUser({
        id: String(this.props.userId),
        email: this.props.userEmail,
        plan: isInternalUser ? 'internal' : 'external',
        name: `${this.props.userFirstName} ${this.props.userLastName}`,
      });

      datadogRum.startSessionReplayRecording();
      hasStartedRecording = true;
    }
  }

  componentWillUnmount() {
    clearInterval(this.tokenValidationPoller);
    window.removeEventListener('focus', this.windowFocused);
    window.removeEventListener('blur', this.windowBlurred);
  }

  async checkToken() {
    try {
      await this.props.validateToken();
    } catch (e) {
      // ignore (handled in render)
    }
  }

  static configureModalDefaults() {
    if (Modal.defaultStyles.overlay && Modal.defaultStyles.content) {
      Modal.defaultStyles.overlay.zIndex = 100;
      Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.6)';
      Modal.defaultStyles.content.top = '50%';
      Modal.defaultStyles.content.left = '50%';
      Modal.defaultStyles.content.right = 'auto';
      Modal.defaultStyles.content.bottom = 'auto';
      Modal.defaultStyles.content.marginRight = '-50%';
      Modal.defaultStyles.content.transform = 'translate(-50%, -50%)';
      Modal.defaultStyles.content.paddingTop = 10;
      Modal.defaultStyles.content.paddingBottom = 80;
      Modal.defaultStyles.content.paddingLeft = 40;
      Modal.defaultStyles.content.paddingRight = 40;
      Modal.defaultStyles.content.backgroundColor = 'transparent';
      Modal.defaultStyles.content.border = '0';
    }
  }

  configureGoogleAnalytics() {
    ReactGA.initialize('UA-67034728-4', {
      debug: false, // set this to true to log actions to console
      titleCase: false,
      gaOptions: {
        userId: String(this.props.userId),
      },
    });
  }

  configureBugsnag() {
    const babelEnv = (typeof process !== 'undefined' && process.env && process.env.BABEL_ENV) || 'undefined';
    if (babelEnv !== 'mocha') {
      // https://docs.bugsnag.com/platforms/javascript/react/#setting-a-user-on-the-client
      bugsnag.user = { id: String(this.props.userId) };
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div className="app-loading centered-in-viewport">
          <ConversationsLoader />
          <h1>Loading Conversations</h1>
        </div>
      );
    }
    if (!this.props.isBrowserSupported) return <UnsupportedBrowser />;
    if (!this.props.tokenIsValid) return <InvalidToken />;

    return (
      <Switch>
        <Route component={ChatAppContainer} exact={true} path="/chat" />
        <Route component={ChatAppContainer} path="/chat/:channel" />
        <Route component={AdminAppContainer} path="/admin" />
        <Route component={UserSwitcherContainer} path="/switch" />
        <Redirect
          exact={true}
          from="/"
          to={'/chat'}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}
